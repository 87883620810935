import {request} from './request'
import Cookies from 'js-cookie'
import qs from 'qs'
// const maker_id=Cookies.get("maker_id")
const maker_id=Cookies.get("maker_id")

export function getProductColors() {
    var maker_id = Cookies.get("maker_id");
    return request({
        url:'/product/getProductColors.php',
        params:{maker_id},
    })
}

export function getCalculate(weight_g,length_cm,width_cm,height_cm,with_battery,with_powder,with_liquid) {
    var maker_id = Cookies.get("maker_id");
    return request({
        url:'/product/getEstimatedShippingCost.php',
        params:{maker_id,weight_g,length_cm,width_cm,height_cm,with_battery,with_powder,with_liquid},
    })
}

export function getPackagingData() {
    var maker_id = Cookies.get("maker_id");
    return request({
        url:'/product/getPackagings.php',
        params:{maker_id},
    })
}
export function getProductCategories() {
    var maker_id = Cookies.get("maker_id");
    return request({
        url:'/product/getProductCategories.php',
        params:{maker_id},
    })
} 

export function getProducts(page,rows_per_page=10) {
    var maker_id = Cookies.get("maker_id");
    return request({
        url:'/product/getProducts.php',
        params:{maker_id,page,rows_per_page},
    })
}

export function getProductDetail(product_id) {
    var maker_id = Cookies.get("maker_id");
    return request({
        url:'/product/getProduct.php',
        params:{maker_id,product_id},
    })
}

export function addProduct(data) {
    data.maker_id= Cookies.get("maker_id");
    data.product=JSON.stringify(data.product)
    return request({
        url:'/product/addProduct.php',
        data:qs.stringify(data),
        method:'post'
    })
}

export function editProduct(data) {
    data.maker_id= Cookies.get("maker_id");
    data.product=JSON.stringify(data.product)
    return request({
        url:'/product/editProduct.php',
        data:qs.stringify(data),
        method:'post'
    })
}

export function deleteProduct(product_id) {
    var maker_id= Cookies.get("maker_id");
    let data={maker_id,product_id}
    return request({
        url:'/product/deleteProduct.php',
        data:qs.stringify(data),
        method:'post'
    })
}
// https://shady.ecomgear.cn/kenneth_testing/maker/product/getProductSalesSummary.php?maker_id=1&type=ebay&dara_productCode=IH-5678
export function getProductSalesSummary(type,dara_productCode){
    var maker_id= Cookies.get("maker_id");
    // var dara_productCode = '=IH-5678';
    return request({
        url:'/product/getProductSalesSummary.php',
        params:{maker_id,type,dara_productCode},
    })
}
export function publishProduct(product_id) {
    var maker_id= Cookies.get("maker_id");
    let data={
        maker_id,
        product_id}
    return request({
        url:'/product/publishProduct.php',
        data:qs.stringify(data),
        method:'post'
    })
}






/**
 * 
 * 
//Product
[GET] http://shady.ecomgear.cn/kenneth_testing/maker/product/getProductColors.php?maker_id=1

[GET] http://shady.ecomgear.cn/kenneth_testing/maker/product/getProductCategories.php?maker_id=1

[GET] http://shady.ecomgear.cn/kenneth_testing/maker/product/getProducts.php?page=1&maker_id=1

[GET] http://shady.ecomgear.cn/kenneth_testing/maker/product/getProduct.php?maker_id=1&product_id=1

[POST] http://shady.ecomgear.cn/kenneth_testing/maker/product/addProduct.php
maker_id=1
product={"product_variations":[{"sku":"aaa","length_cm":"1","width_cm":"2","height_cm":"3","weight_g":"4","color":"BL","size":"XL","price_usd":"2.99","img":"http1.jpg"}],"imgs":[{"priority":1,"img":"http6.jpg"},{"priority":2,"img":"http7.jpg"}],"title":"titlexxxx","is_published":0,"description":"descriptionxxx","category_id":123}


[POST] http://shady.ecomgear.cn/kenneth_testing/maker/product/editProduct.php
maker_id=1
product_id=1
product={"product_variations":[{"sku":"aaa","length_cm":"1","width_cm":"2","height_cm":"3","weight_g":"4","color":"BL","size":"XL","price_usd":"2.99","img":"http1.jpg"}],"imgs":[{"priority":1,"img":"http6.jpg"},{"priority":2,"img":"http7.jpg"}],"title":"titlexxxx","is_published":0,"description":"descriptionxxx","category_id":123}


[POST] http://shady.ecomgear.cn/kenneth_testing/maker/product/publishProduct.php
maker_id=1
product_id=1
 */