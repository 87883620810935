<template>
     <div>
         <a-page-header
            title="Products"
            class="header_title"
        />
         <router-view></router-view>
        <div class='box_content orders'>
          <a-button @click="addProductBtn" type="danger">
          Add Product
        </a-button>
            <a-table @change="pageChange" :pagination="pagination" :rowKey="(record, index) => index" :row-selection="rowSelection" :columns="columns" :data-source="productData" :loading="loading">
              <img class="proimg" slot="main_img" slot-scope="text,data" :src='text' alt="error" @click="action_product(data.product_id)"/>
              <div slot="is_published" slot-scope="text">{{text}}</div>
                <a-tag
                  slot="is_published" slot-scope="text,data,index"
                  :key="text"
                  :color="'geekblue'"
                  @click="publishProductBtn(data.product_id,index)"
                >
                  {{statusText(text)}}
                </a-tag>
                <span slot="product_id" slot-scope="text,data,index">
                    <a @click="editBtn(data.product_id)">Edit</a>
                    <a-divider type="vertical" />
                    <!-- <a @click="deleteBtn(data.product_id,index)"><a-icon type="delete" /></a> -->
                  <a-popconfirm
                    title="Are you sure delete this product?"
                    ok-text="Yes"
                    cancel-text="No"
                    @confirm="deleteBtn(data.product_id,index)"
                    @cancel="cancel"
                  ><a href="javascript:void(0)">Delete</a></a-popconfirm>
                </span>
          </a-table>
    </div>
    </div>
</template>

<script>
const columns = [
  {
    title: "Img",
    key: "main_img",
    dataIndex: "main_img",
    scopedSlots: { customRender: "main_img" },
  },
  {
    title: "Title",
    key: "title",
    dataIndex: "title",
    scopedSlots: { customRender: "title" },
  },
  {
    title: "Status",
    key: "is_published",
    dataIndex: "is_published",
    scopedSlots: { customRender: "is_published" },
  },
  {
    title: "Action",
    key: "product_id",
    dataIndex: "product_id",
    scopedSlots: { customRender: "product_id" },
  },
];

import {
  getProducts,
  deleteProduct,
  publishProduct,
} from "@/network/prorequest";
export default {
  name: "ProductList",
  components: {},
  directives: {},
  data() {
    return {
      loading: false,
      productData: [],
      columns,
      pagination: { defaultPageSize: 10, current: 1, total: 0 },
    };
  },
  created() {
    this.getProductsData(1);
  },

  mounted() {},
  computed: {
    statusText() {
      return function (e) {
        return e == 0 ? "Hold" : "Active";
      };
    },
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(
            `selectedRowKeys: ${selectedRowKeys}`,
            "selectedRows: ",
            selectedRows
          );
        },
        getCheckboxProps: (record) => ({
          props: {
            disabled: record.name === "Disabled User", // Column configuration not to be checked
            name: record.name,
          },
        }),
      };
    },
  },
  methods: {
    action_product(product_id){
      this.$router.push({
        path: `/product`,
        query: { product_id: product_id },
      });
    },
    cancel(e) {
      // console.log(e);
      // this.$message.error('Click on No');
    },
    publishProductBtn(product_id, index) {
      index += (this.pagination.current - 1) * 10;
      publishProduct(product_id).then((res) => {
        if (res.header.code == 200) {
          let newobj = this.productData[index];
          newobj.is_published = 1;

          this.$set(this.productData, index, newobj);
        }
      });
    },
    pageChange(page) {
      this.pagination = page;
    },
    getProductsData(page) {
      this.loading = true;
      getProducts(page).then((res) => {
        if (res.header.code == 200) {
          //  console.log(res.body.total_rows)
          this.$set(this.pagination, "total", res.body.total_rows);
          this.productData =
            page === 1
              ? res.body.products
              : this.productData.concat(res.body.products);
          // console.log(this.data)
          let fornum = Math.ceil(res.body.total_rows / 10); //计算一共有多少页总页数/每页25
          if (page != fornum && fornum > 0) {
            console.log(page != 2);
            this.getProductsData(page + 1);
          }

          this.loading = false;
          return;
        }
        // this.$message.info(res.header.msg);
      });
    },
    addProductBtn() {
      this.$router.push("/products/editproduct");
    },
    //删除
    deleteBtn(e, index) {
      deleteProduct(e).then((res) => {
        console.log(res);
        if (res.header.code == 200) {
          this.productData.splice(index, 1);
          this.$message.success("delete success!");
        }
      });
    },
    //编辑
    editBtn(e) {
      // console.log(e)
      this.$router.push({
        path: `/products/editproduct`,
        query: { product_id: e },
      });
    },
  },
};
</script>

<style  scoped>
.orders .wrap_order {
  box-shadow: 0 0 10px rgba(143, 144, 165, 0.7);
  padding: 15px 0;
  overflow-x: auto;
}
.orders .ordertable {
  width: 100%;
  border-spacing: 0;
}
.orders .ordertable thead {
  border-bottom: 1px solid #e5e5ec;
}
.orders .ordertable th {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  padding: 0 12px 12px;
  line-height: 20px;
  color: #272742;
}
.orders .ordertable td {
  padding: 12px;
  white-space: nowrap;
  font-size: 14px;
  color: #666666;
}
.orders .ordertable .td_black {
  font-weight: 550;
}
.orders .ordertable tr {
  border-bottom: 1px solid #e5e5ec;
  cursor: pointer;
}
.orders .ordertable tr .paytosupplierbtn {
  background-color: #ff1053;
  color: #ffffff;
}

.orders .proimg {
  width: 50px;
  height: 50px;
  cursor:pointer;
}
</style>